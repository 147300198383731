<template>
  <v-simple-table class="mb-8">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t("Page") }}
          </th>
          <th class="text-left">
            <v-layout align-center fill-height>
              <v-checkbox
                class="mt-0 pt-0"
                v-model="getCheckRead"
                hide-details="auto"
              >
              </v-checkbox>
              {{ $t("Read") }}
            </v-layout>
          </th>
          <th class="text-left">
            <v-layout align-center fill-height>
              <v-checkbox
                class="mt-0 pt-0"
                v-model="getCheckWrite"
                hide-details="auto"
              >
              </v-checkbox>
              {{ $t("Write") }}
            </v-layout>
          </th>
          <th class="text-left">
            <v-layout align-center fill-height>
              <v-checkbox
                class="mt-0 pt-0"
                v-model="getCheckDelete"
                hide-details="auto"
              ></v-checkbox>
              {{ $t("Remove") }}
            </v-layout>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="getUserRoles">
          <tr v-for="(item, index) in getUserRoles" :key="index">
            <td>
              {{ item.PageName[currentLanguage] }}
            </td>
            <td>
              <v-layout align-center fill-height>
                <v-checkbox
                  v-model="item.Roles"
                  :value="0"
                  class="mt-0 pt-0"
                  hide-details="auto"
                  @change="changeItem(index)"
                ></v-checkbox>
              </v-layout>
            </td>
            <td>
              <v-layout align-center fill-height>
                <v-checkbox
                  :disabled="!item.Roles.includes(0)"
                  v-model="item.Roles"
                  :value="1"
                  class="mt-0 pt-0"
                  hide-details="auto"
                  @change="changeItem(index)"
                ></v-checkbox>
              </v-layout>
            </td>
            <td>
              <v-layout align-center fill-height>
                <v-checkbox
                  :disabled="!item.Roles.includes(0)"
                  v-model="item.Roles"
                  :value="2"
                  class="mt-0 pt-0"
                  hide-details="auto"
                  @change="changeItem(index)"
                ></v-checkbox>
              </v-layout>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <v-layout justify-center align-center fill-height>
                <v-spacer></v-spacer>
                {{ $t("No data available") }}
                <v-spacer></v-spacer>
              </v-layout>
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    userRolesProps: {
      type: Array,
    },
  },
  data() {
    return {
      userRoles: this.userRolesProps,
    };
  },
  watch: {
    userRolesProps: function (items) {
      this.userRoles = items;
    },
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getUserRoles() {
      return this.userRoles;
    },
    getCheckRead: {
      get() {
        if (this.userRoles.length == 0) {
          return false;
        }
        const includesZero = (val) => val.Roles.includes(0);
        return this.userRoles.every(includesZero);
      },
      set(val) {
        if (!val) {
          this.userRoles.forEach((element, index) => {
            this.userRoles[index].Roles.splice(0, element.Roles.length);
          });
        } else {
          this.userRoles.forEach((element, index) => {
            if (!element.Roles.includes(0)) {
              this.userRoles[index].Roles.push(0);
            }
          });
        }
      },
    },
    getCheckWrite: {
      get() {
        if (this.userRoles.length == 0) {
          return false;
        }
        const includesOne = (val) => val.Roles.includes(1);
        return this.userRoles.every(includesOne);
      },
      set(val) {
        if (val) {
          this.userRoles.forEach((element, index) => {
            if (!element.Roles.includes(1) && element.Roles.includes(0)) {
              this.userRoles[index].Roles.push(1);
            }
          });
        } else {
          this.userRoles.forEach((element, index) => {
            if (element.Roles.includes(1)) {
              const indexItem = this.userRoles[index].Roles.indexOf(1);
              this.userRoles[index].Roles.splice(indexItem, 1);
            }
          });
        }
      },
    },
    getCheckDelete: {
      get() {
        if (this.userRoles.length == 0) {
          return false;
        }
        const includesTwo = (val) => val.Roles.includes(2);
        return this.userRoles.every(includesTwo);
      },
      set(val) {
        if (val) {
          this.userRoles.forEach((element, index) => {
            if (!element.Roles.includes(2) && element.Roles.includes(0)) {
              this.userRoles[index].Roles.push(2);
            }
          });
        } else {
          this.userRoles.forEach((element, index) => {
            if (element.Roles.includes(2)) {
              const indexItem = this.userRoles[index].Roles.indexOf(2);
              this.userRoles[index].Roles.splice(indexItem, 1);
            }
          });
        }
      },
    },
  },
  methods: {
    changeItem(index) {
      if (!this.userRoles[index].Roles.includes(0)) {
        this.userRoles[index].Roles.splice(
          0,
          this.userRoles[index].Roles.length
        );
      }
      //this.userRoles = JSON.parse(JSON.stringify(this.userRoles));
    },
  },
};
</script>

<style>
</style>